import { boot } from 'quasar/wrappers'
import { initializeApp } from 'firebase/app'
import { initializeAuth, getAuth, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

var firebaseAuth = null
var firebaseFirestore = null

export default boot(async ({ app }) => {
  const firebaseApp = await initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABBASE_URL,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
  })

  firebaseAuth = await initializeAuth(firebaseApp, {
    persistence: browserLocalPersistence // This uses localStorage
  })

  const firebaseFunctions = await getFunctions(firebaseApp)
  firebaseFirestore = await getFirestore(firebaseApp)
  const firebaseStorage = await getStorage(firebaseApp)

  app.provide('$firebase', firebaseApp)
  app.provide('$firebaseAuth', getAuth(firebaseApp))
  app.provide('$functions', firebaseFunctions)
  app.provide('$firestore', firebaseFirestore)
  app.provide('$firebaseStorage', firebaseStorage)
  console.log('App Initialized Successfully')
})

var userId = null

const isLoggedIn = async function() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async user => {
      unsubscribe()
      if (user) userId = user.uid
      resolve(user)
    }, reject)
  })
}

const getUser = async function() {
  if (!userId) return false
  return new Promise(async (resolve, reject) => {
    const u = await getDoc(doc(firebaseFirestore, 'users', userId))
    if (u.exists()) resolve(u.data())
    else reject
  })
}

// const isLoggedIn = async function() {
//   try {
//     await new Promise((resolve, reject) => {
//       onAuthStateChanged(
//         firebaseAuth,
//         async user => {
//           if (user) {
//             const u = await getDoc(doc(firebaseFirestore, 'users', user.uid))
//             resolve(u.data())
//           } else {
//             reject('no user logged in')
//           }
//         },
//         error => {
//           console.log('rejecting  isLoggedIn with error: ' + error)
//           reject(error)
//         }
//       )
//     })
//     return true
//   } catch (error) {
//     console.log('error: ' + error)
//     return false
//   }
// }

export { isLoggedIn, getUser }
