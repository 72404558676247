import { Platform } from 'quasar'
import { route } from 'quasar/wrappers'

import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import mobileRoutes from './MobileRoutes'
import webRoutes from './WebRoutes'

import { isLoggedIn } from 'src/boot/firebase'
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function(/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER ? createMemoryHistory : process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: Platform.is.mobile ? mobileRoutes : webRoutes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const isAuth = await isLoggedIn()
    console.log('Is Authenticated? ' + isAuth)
    if (requiresAuth && !isAuth) {
      console.log('Not authed but page requires auth')
      next({ name: 'auth', replace: true })
      return
    } else {
      //User is auth
      if (to.name === 'getstarted' && isAuth) next({ name: 'users' })
      //Don't create another account if already logged in.
      else if (to.name === 'auth' && isAuth) next({ name: 'companies' })
      //Route requires a a role the user does not have
      //Don't go to auth if already auth
      else {
        next()
        return
      }
    }
  })

  return Router
})
