import { ref, reactive, computed, onMounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { doc, onSnapshot, query, collection, where, getDocs, getDoc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import useStripe from './stripe.js'
const currentUser = reactive({
  isAuthenticated: false,
  profile: null,
  company: null,
  subscription: null
})

export default function useCurrentUser() {
  const q = useQuasar()
  const route = useRoute()
  const router = useRouter()
  const firebase = inject('$firebase')
  const firestore = inject('$firestore')
  const firebaseAuth = inject('$firebaseAuth')
  const functions = inject('$functions')
  const { watchSubscription } = useStripe()

  const waiting = ref(false)

  const watchCurrentUserAuth = function() {
    console.log('Start watching current user.')
    onAuthStateChanged(firebaseAuth, async user => {
      console.log('Firebase auth state changed. Is authenticated: ' + !!user)
      if (user) {
        currentUser.isAuthenticated = true
        user
          .getIdTokenResult()
          .then(idTokenResult => {
            console.log('idTokenResult.claims: ' + JSON.stringify(idTokenResult.claims, null, 2))
          })
          .catch(error => {
            console.log(error)
          })

        onSnapshot(doc(firestore, 'users', user.uid), async d => {
          currentUser.profile = d.data()
          currentUser.profile.isAccountOwner = currentUser.profile.companyRole === 'Primary Account'
          currentUser.profile.isManager = currentUser.profile.companyRole === 'Manager'
          currentUser.profile.isRep = !currentUser.profile.isAccountOwner && !currentUser.profile.isManager
          currentUser.company = (await getDoc(doc(firestore, 'talonCompanies', currentUser.profile.company))).data()
          watchSubscription(currentUser.profile.company)
        })
      } else {
        console.log('No User Authenticated')
        currentUser.isAuthenticated = false
        currentUser.profile = null
      }
    })
  }

  const logout = function() {
    q.dialog({
      title: 'Logout',
      message: 'Are you sure you would like to logout?',
      ok: {
        label: 'Logout',
        push: false,
        noCaps: true,
        unelevated: true,
        color: 'secondary'
      },
      cancel: {
        noCaps: true,
        flat: true,
        color: 'accent'
      }
    }).onOk(() => {
      signOut(firebaseAuth)
        .then(() => {
          router.replace({ name: 'auth' })
        })
        .catch(function(error) {
          console.log('Error logging out: ' + error)
        })
    })
  }

  return {
    currentUser,
    watchCurrentUserAuth,
    logout
  }
}
