const routes = [
  {
    path: '/',
    component: () => import('layouts/MobileLayout.vue'),
    children: [
      { path: '/', name: 'home', meta: { pageTitle: 'Home', requiresAuth: true, hideTop: true }, component: () => import('pages/mobile/MobileHome.vue') },
      { path: '/companies', name: 'companies', meta: { pageTitle: 'Companies', requiresAuth: true }, component: () => import('pages/mobile/MobileCompanies.vue') },
      { path: '/viewCompany', name: 'viewCompany', props: true, meta: { pageTitle: 'View Company', requiresAuth: true, subPage: true }, component: () => import('pages/mobile/MobileViewCompany.vue') },
      {
        path: '/meeting/:leadId',
        name: 'meeting',
        props: 'true',
        meta: { pageTitle: 'Record Meeting', requiresAuth: true, subPage: true },
        component: () => import('pages/Meeting.vue')
      },
      { path: '/reports', name: 'reports', meta: { pageTitle: 'Reports', requiresAuth: true }, component: () => import('pages/mobile/MobileReports.vue') },
      { path: '/viewReport', name: 'viewReport', props: true, meta: { pageTitle: 'Report', requiresAuth: true, subPage: true }, component: () => import('pages/mobile/MobileViewReport.vue') },

      { path: '/settings', name: 'settings', meta: { pageTitle: 'Settings', requiresAuth: true }, component: () => import('pages/mobile/MobileSettings.vue') }
    ]
  },
  { path: '/auth', name: 'auth', meta: { pageTitle: 'Auth' }, component: () => import('pages/Auth.vue') },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
