import { getUser } from 'src/boot/firebase'

const webRoutes = [
  {
    path: '/',
    component: () => import('layouts/WebLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '/', meta: { pageTitle: 'Customers', requiresAuth: true, hideTop: true }, component: () => import('pages/web/WebCompanies.vue') },
      { path: '/prospects', name: 'prospects', meta: { pageTitle: 'Prospects', requiresAuth: true, hideTop: true }, component: () => import('pages/web/WebProspects.vue') },
      {
        path: '/prospects/:prospectId',
        name: 'prospect',
        meta: { pageTitle: 'Prospects', requiresAuth: true, subPage: true },
        component: () => import('pages/web/WebCompany.vue')
      },
      { path: '/customers', name: 'customers', meta: { pageTitle: 'Customers', requiresAuth: true, hideTop: true }, component: () => import('pages/web/WebCompanies.vue') },
      {
        path: '/companies/:companyId',
        name: 'company',
        meta: { pageTitle: 'Company', requiresAuth: true, subPage: true },
        component: () => import('pages/web/WebCompany.vue')
      },
      {
        path: '/meeting/:meetingId',
        name: 'meeting',
        meta: { pageTitle: 'Meeting', requiresAuth: true, subPage: true },
        component: () => import('pages/web/WebMeeting.vue')
      },
      {
        path: '/reports',
        component: () => import('layouts/WebReportLayout.vue'),
        meta: { roles: ['Primary Account', 'Manager'], requiresAuth: true },
        children: [
          { path: 'prospect', name: 'WebProspectReport', component: () => import('pages/web/reports/WebProspectReport.vue') },
          { path: 'map', name: 'WebMapReport', component: () => import('pages/web/reports/WebMapReport.vue') },
          { path: 'customer', name: 'WebCustomerReport', component: () => import('pages/web/reports/WebCustomerReport.vue') },
          { path: 'representative', name: 'WebRepresentativeReport', component: () => import('pages/web/reports/WebRepresentativeReport.vue') },
          { path: 'manager', name: 'WebManagerReport', component: () => import('pages/web/reports/WebManagerReport.vue') },
          { path: 'custommetrics', name: 'WebCustomMetricReport', component: () => import('pages/web/reports/WebCustomMetricsReport.vue') }
        ],
        beforeEnter: async (to, from, next) => {
          const user = await getUser()
          if (to.meta.roles.includes(user.companyRole)) next()
          else next({ name: 'companies' })
        }
      },
      // {
      //   path: '/meeting/:leadId',
      //   name: 'meeting',
      //   meta: { pageTitle: 'Record Meeting', requiresAuth: true, subPage: true },
      //   component: () => import('pages/Meeting.vue')
      // },
      {
        path: '/users',
        name: 'users',
        meta: { roles: ['Primary Account', 'Manager'], pageTitle: 'Users & Billing', requiresAuth: true },
        component: () => import('pages/web/WebUsers.vue'),
        beforeEnter: async (to, from, next) => {
          const user = await getUser()
          if (to.meta.roles.includes(user.companyRole)) next()
          else next({ name: 'companies' })
        }
      },
      { path: '/account', name: 'account', meta: { pageTitle: 'Account', requiresAuth: true }, component: () => import('pages/web/WebAccount.vue') },
      { path: '/metrics', name: 'metrics', meta: { pageTitle: 'Metrics', requiresAuth: true }, component: () => import('pages/web/WebCompanyMetrics.vue') }
    ]
  },
  { path: '/auth', name: 'auth', meta: { pageTitle: 'Auth' }, component: () => import('pages/Auth.vue') },
  { path: '/getstarted', name: 'getstarted', meta: { pageTitle: 'Get Started' }, component: () => import('pages/GetStarted.vue') },
  { path: '/buy', name: 'buy', meta: { pageTitle: 'Buy' }, component: () => import('pages/web/WebBuy.vue') },
  { path: '/support', name: 'suppor', meta: { pageTitle: 'Support' }, component: () => import('pages/web/Support.vue') },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default webRoutes
