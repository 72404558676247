<template>
  <router-view />
</template>
<script>
import { defineComponent, ref, inject } from 'vue'
import useCurrentUser from './composables/currentUser.js'
import { doc, onSnapshot, query, collection, where, getDocs, updateDoc } from 'firebase/firestore'

export default defineComponent({
  name: 'App',
  setup() {
    const firestore = inject('$firestore')
    const { watchCurrentUserAuth, currentUser } = useCurrentUser()
    watchCurrentUserAuth()
    return {}
  }
})
</script>
