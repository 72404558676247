import { ref, reactive, computed, onMounted, inject } from 'vue'
import { doc, onSnapshot, query, collection, where, getDocs, updateDoc } from 'firebase/firestore'
import { httpsCallable } from '@firebase/functions'

import { serverTimestamp } from '@firebase/firestore'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

const subscription = ref(null)
var subscriptionWatcher = null

export default function useStripe() {
  const q = useQuasar()
  const router = useRouter()

  const firestore = inject('$firestore')
  const functions = inject('$functions')

  const watchSubscription = function(company) {
    if (subscriptionWatcher) subscriptionWatcher() //Terminate any existing watcher
    subscriptionWatcher = onSnapshot(query(collection(firestore, 'talonCompanies', company, 'subscriptions')), snap => {
      if (snap.empty) {
        console.log('Unable to find any subscriptions at all. Send to buy a subscription.')
        subscription.value = null
      } else {
        // const activeSubscription = snap.docs.filter((d) => {
        //   return d.data().
        // })
        subscription.value = snap.docs[0].data()
      }
    })
  }

  const manageSubscription = async function(profile) {
    if (!profile.isAccountOwner) {
      q.notify({ type: 'negative', message: 'Only the Account Owner may update billing details.' })
      return
    }

    q.loading.show()
    httpsCallable(
      functions,
      'stripe-createPortalLink'
    )({
      company: profile.company,
      return_url: window.location.origin + '/' + router.resolve(`/users`).href
    })
      .then(data => {
        q.loading.hide()
        if (data) {
          window.location.assign(data.data.url)
        }
      })
      .catch(e => {
        console.log('error: ' + e)
        q.loading.hide()
      })
  }

  const hasSubscription = computed(() => {
    return !!subscription.value
  })

  const hasPaymentIssue = computed(() => {
    if (!subscription.value) return false
    else return ['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.value.status)
  })

  return {
    manageSubscription,
    watchSubscription,
    subscription,
    hasSubscription,
    hasPaymentIssue
  }
}
